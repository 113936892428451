import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LegalTemplate from './legal-template';
import ContactType from './contact-type';
import DocumentType from './document-type';
import Organism from './organism';
import Location from './location';
import Rate from './rate';
import City from './city';
import Connector from './connector';
import LegalStatus from './legal-status';
import Bundle from './bundle';
import Document from './document';
import DocumentPipe from './document-pipe';
import ProfileAdditionalAttribute from './profile-additional-attribute';
import WayType from './way-type';
import Contact from './contact';
import ProvidedService from './provided-service';
import Unit from './unit';
import Product from './product';
import Legal from './legal';
import Duration from './duration';
import Profile from './profile';
import Country from './country';
import Contract from './contract';
import PlatformUser from './platform-user';
import Vat from 'app/entities/vat';
import MultiRiskInsuranceRateGrid from 'app/entities/multi-risk-insurance-rate-grid';
import CeilingRemovalCoefficient from 'app/entities/ceiling-removal-coefficient';
import LifeInsuranceCoefficient from 'app/entities/life-insurance-coefficient';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="legal-template/*" element={<LegalTemplate />} />
        <Route path="contact-type/*" element={<ContactType />} />
        <Route path="document-type/*" element={<DocumentType />} />
        <Route path="organism/*" element={<Organism />} />
        <Route path="location/*" element={<Location />} />
        <Route path="rate/*" element={<Rate />} />
        <Route path="city/*" element={<City />} />
        <Route path="connector/*" element={<Connector />} />
        <Route path="legal-status/*" element={<LegalStatus />} />
        <Route path="bundle/*" element={<Bundle />} />
        <Route path="document/*" element={<Document />} />
        <Route path="document-pipe/*" element={<DocumentPipe />} />
        <Route path="profile-additional-attribute/*" element={<ProfileAdditionalAttribute />} />
        <Route path="way-type/*" element={<WayType />} />
        <Route path="contact/*" element={<Contact />} />
        <Route path="provided-service/*" element={<ProvidedService />} />
        <Route path="unit/*" element={<Unit />} />
        <Route path="product/*" element={<Product />} />
        <Route path="legal/*" element={<Legal />} />
        <Route path="duration/*" element={<Duration />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="country/*" element={<Country />} />
        <Route path="contract/*" element={<Contract />} />
        <Route path="platform-user/*" element={<PlatformUser />} />
        <Route path="vat/*" element={<Vat />} />
        <Route path="multi-risk-insurance-rate-grid/*" element={<MultiRiskInsuranceRateGrid />} />
        <Route path="ceiling-removal-coefficient/*" element={<CeilingRemovalCoefficient />} />
        <Route path="life-insurance-coefficient/*" element={<LifeInsuranceCoefficient />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
