import dayjs from 'dayjs';
import { IDocumentType } from 'app/shared/model/document-type.model';
import { IDocumentPipe } from 'app/shared/model/document-pipe.model';

export interface IDocument {
  id?: number;
  slug?: string | null;
  fileName?: string | null;
  filePath?: string | null;
  fileExternalID?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  isActive?: boolean | null;
  documentType?: IDocumentType | null;
  documentPipes?: IDocumentPipe[] | null;
}

export const defaultValue: Readonly<IDocument> = {
  isActive: false,
};
