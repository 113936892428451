import dayjs from 'dayjs';
import { ILegal } from 'app/shared/model/legal.model';

export interface ILegalStatus {
  id?: number;
  slug?: string | null;
  isActive?: boolean | null;
  updatedAt?: string | null;
  legals?: ILegal[] | null;
}

export const defaultValue: Readonly<ILegalStatus> = {
  isActive: false,
};
