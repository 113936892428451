import dayjs from 'dayjs';
import { ILegal } from 'app/shared/model/legal.model';
import { IPlatformUser } from 'app/shared/model/platform-user.model';
import { IProduct } from 'app/shared/model/product.model';
import { ILocation } from 'app/shared/model/location.model';
import { IOrganism } from 'app/shared/model/organism.model';

export interface IUnit {
  id?: number;
  slug?: string | null;
  slugDescription?: string | null;
  updatedAt?: string | null;
  legals?: ILegal[] | null;
  platformUsers?: IPlatformUser[] | null;
  products?: IProduct[] | null;
  location?: ILocation | null;
  organism?: IOrganism | null;
}

export const defaultValue: Readonly<IUnit> = {};
