import dayjs from 'dayjs';
import { IProvidedService } from 'app/shared/model/provided-service.model';

export interface IConnector {
  id?: number;
  slug?: string | null;
  isActive?: boolean | null;
  vaultFile?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  providedService?: IProvidedService | null;
}

export const defaultValue: Readonly<IConnector> = {
  isActive: false,
};
