import dayjs from 'dayjs';
import { IRate } from 'app/shared/model/rate.model';
import { IUnit } from 'app/shared/model/unit.model';
import { RateType } from 'app/shared/model/enumerations/rate-type.model';
import { InsuranceType } from 'app/shared/model/enumerations/insurance-type.model';

export interface IProduct {
  id?: number;
  slug?: string | null;
  slugDescription?: string | null;
  rateType?: keyof typeof RateType | null;
  insuranceType?: keyof typeof InsuranceType | null;
  code?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  rates?: IRate[] | null;
  unit?: IUnit | null;
}

export const defaultValue: Readonly<IProduct> = {};
