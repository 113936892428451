import legalTemplate from 'app/entities/legal-template/legal-template.reducer';
import contactType from 'app/entities/contact-type/contact-type.reducer';
import documentType from 'app/entities/document-type/document-type.reducer';
import organism from 'app/entities/organism/organism.reducer';
import location from 'app/entities/location/location.reducer';
import rate from 'app/entities/rate/rate.reducer';
import city from 'app/entities/city/city.reducer';
import connector from 'app/entities/connector/connector.reducer';
import legalStatus from 'app/entities/legal-status/legal-status.reducer';
import bundle from 'app/entities/bundle/bundle.reducer';
import document from 'app/entities/document/document.reducer';
import documentPipe from 'app/entities/document-pipe/document-pipe.reducer';
import profileAdditionalAttribute from 'app/entities/profile-additional-attribute/profile-additional-attribute.reducer';
import wayType from 'app/entities/way-type/way-type.reducer';
import contact from 'app/entities/contact/contact.reducer';
import providedService from 'app/entities/provided-service/provided-service.reducer';
import unit from 'app/entities/unit/unit.reducer';
import product from 'app/entities/product/product.reducer';
import legal from 'app/entities/legal/legal.reducer';
import duration from 'app/entities/duration/duration.reducer';
import profile from 'app/entities/profile/profile.reducer';
import country from 'app/entities/country/country.reducer';
import contract from 'app/entities/contract/contract.reducer';
import platformUser from 'app/entities/platform-user/platform-user.reducer';
import vat from 'app/entities/vat/vat.reducer';
import multiRiskInsuranceRateGrid from 'app/entities/multi-risk-insurance-rate-grid/multi-risk-insurance-rate-grid.reducer';
import ceilingRemovalCoefficient from 'app/entities/ceiling-removal-coefficient/ceiling-removal-coefficient.reducer';
import lifeInsuranceCoefficient from 'app/entities/life-insurance-coefficient/life-insurance-coefficient.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  legalTemplate,
  contactType,
  documentType,
  organism,
  location,
  rate,
  city,
  connector,
  legalStatus,
  bundle,
  document,
  documentPipe,
  profileAdditionalAttribute,
  wayType,
  contact,
  providedService,
  unit,
  product,
  legal,
  duration,
  profile,
  country,
  contract,
  platformUser,
  vat,
  multiRiskInsuranceRateGrid,
  ceilingRemovalCoefficient,
  lifeInsuranceCoefficient,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
