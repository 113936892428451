import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Vat from './vat';
import VatDetail from './vat-detail';
import VatUpdate from './vat-update';
import VatDeleteDialog from './vat-delete-dialog';

const VatRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Vat />} />
    <Route path="new" element={<VatUpdate />} />
    <Route path=":id">
      <Route index element={<VatDetail />} />
      <Route path="edit" element={<VatUpdate />} />
      <Route path="delete" element={<VatDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default VatRoutes;
