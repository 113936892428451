import dayjs from 'dayjs';
import { ILegal } from 'app/shared/model/legal.model';
import { IDocument } from 'app/shared/model/document.model';

export interface IDocumentPipe {
  id?: number;
  slug?: string | null;
  path?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  isActive?: boolean | null;
  legals?: ILegal[] | null;
  documents?: IDocument[] | null;
}

export const defaultValue: Readonly<IDocumentPipe> = {
  isActive: false,
};
