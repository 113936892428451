import { IRate } from 'app/shared/model/rate.model';
import { IProfileAdditionalAttribute } from 'app/shared/model/profile-additional-attribute.model';

export interface IProfile {
  id?: number;
  title?: string | null;
  residencyCountry?: string | null;
  citizenship?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  updatedAt?: string | null;
  rates?: IRate[] | null;
  profileAdditionalAttributes?: IProfileAdditionalAttribute[] | null;
}

export const defaultValue: Readonly<IProfile> = {};
