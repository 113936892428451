import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { createEntity, getEntity, reset, updateEntity } from './life-insurance-coefficient.reducer';

export const LifeInsuranceCoefficientUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const lifeInsuranceCoefficientEntity = useAppSelector(state => state.lifeInsuranceCoefficient.entity);
  const loading = useAppSelector(state => state.lifeInsuranceCoefficient.loading);
  const updating = useAppSelector(state => state.lifeInsuranceCoefficient.updating);
  const updateSuccess = useAppSelector(state => state.lifeInsuranceCoefficient.updateSuccess);

  const handleClose = () => {
    navigate('/life-insurance-coefficient' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...lifeInsuranceCoefficientEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...lifeInsuranceCoefficientEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="matrixServiceApp.lifeInsuranceCoefficient.home.createOrEditLabel" data-cy="LifeInsuranceCoefficientCreateUpdateHeading">
            <Translate contentKey="matrixServiceApp.lifeInsuranceCoefficient.home.createOrEditLabel">
              Create or edit a life insurance coefficient
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="life-insurance-coefficient-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('matrixServiceApp.lifeInsuranceCoefficient.code')}
                id="life-insurance-coefficient-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{
                  required: translate('matrixServiceApp.lifeInsuranceCoefficient.validationError.required', {
                    field: translate('matrixServiceApp.lifeInsuranceCoefficient.code'),
                  })
                }}
              />
              <ValidatedField
                label={translate('matrixServiceApp.lifeInsuranceCoefficient.durationMin')}
                id="life-insurance-coefficient-duration-min"
                name="durationMin"
                data-cy="durationMin"
                step="0.00001"
                type="number"
                validate={{
                  required: translate('matrixServiceApp.lifeInsuranceCoefficient.validationError.required', {
                    field: translate('matrixServiceApp.lifeInsuranceCoefficient.durationMin'),
                  })
                }}
              />
              <ValidatedField
                label={translate('matrixServiceApp.lifeInsuranceCoefficient.durationMax')}
                id="life-insurance-coefficient-duration-max"
                name="durationMax"
                data-cy="durationMax"
                type="number"
                validate={{
                  required: translate('matrixServiceApp.lifeInsuranceCoefficient.validationError.required', {
                    field: translate('matrixServiceApp.lifeInsuranceCoefficient.durationMax'),
                  })
                }}
              />
              <ValidatedField
                label={translate('matrixServiceApp.lifeInsuranceCoefficient.coeff')}
                id="life-insurance-coefficient-coeff"
                name="coeff"
                data-cy="coeff"
                type="number"
                step="0.000001"
                validate={{
                  required: translate('matrixServiceApp.lifeInsuranceCoefficient.validationError.required', {
                    field: translate('matrixServiceApp.lifeInsuranceCoefficient.coeff'),
                  })
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/life-insurance-coefficient" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LifeInsuranceCoefficientUpdate;
