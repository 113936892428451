import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DocumentPipe from './document-pipe';
import DocumentPipeDetail from './document-pipe-detail';
import DocumentPipeUpdate from './document-pipe-update';
import DocumentPipeDeleteDialog from './document-pipe-delete-dialog';

const DocumentPipeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DocumentPipe />} />
    <Route path="new" element={<DocumentPipeUpdate />} />
    <Route path=":id">
      <Route index element={<DocumentPipeDetail />} />
      <Route path="edit" element={<DocumentPipeUpdate />} />
      <Route path="delete" element={<DocumentPipeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DocumentPipeRoutes;
