import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Duration from './duration';
import DurationDetail from './duration-detail';
import DurationUpdate from './duration-update';
import DurationDeleteDialog from './duration-delete-dialog';

const DurationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Duration />} />
    <Route path="new" element={<DurationUpdate />} />
    <Route path=":id">
      <Route index element={<DurationDetail />} />
      <Route path="edit" element={<DurationUpdate />} />
      <Route path="delete" element={<DurationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DurationRoutes;
