import dayjs from 'dayjs';
import { ILegal } from 'app/shared/model/legal.model';
import { IProvidedService } from 'app/shared/model/provided-service.model';

export interface IContract {
  id?: number;
  slug?: string | null;
  slugDescription?: string | null;
  updatedAt?: string | null;
  legals?: ILegal[] | null;
  providedServices?: IProvidedService[] | null;
}

export const defaultValue: Readonly<IContract> = {};
