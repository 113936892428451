import dayjs from 'dayjs';
import { ILegal } from 'app/shared/model/legal.model';

export interface ILegalTemplate {
  id?: number;
  slug?: string | null;
  dynamicHTtmlContent?: string | null;
  updatedAt?: string | null;
  legals?: ILegal[] | null;
}

export const defaultValue: Readonly<ILegalTemplate> = {};
