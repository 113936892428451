import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProduct } from 'app/shared/model/product.model';
import { getEntities as getProducts } from 'app/entities/product/product.reducer';
import { IProfile } from 'app/shared/model/profile.model';
import { getEntities as getProfiles } from 'app/entities/profile/profile.reducer';
import { IDuration } from 'app/shared/model/duration.model';
import { getEntities as getDurations } from 'app/entities/duration/duration.reducer';
import { IRate } from 'app/shared/model/rate.model';
import { getEntity, updateEntity, createEntity, reset } from './rate.reducer';

export const RateUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const products = useAppSelector(state => state.product.entities);
  const profiles = useAppSelector(state => state.profile.entities);
  const durations = useAppSelector(state => state.duration.entities);
  const rateEntity = useAppSelector(state => state.rate.entity);
  const loading = useAppSelector(state => state.rate.loading);
  const updating = useAppSelector(state => state.rate.updating);
  const updateSuccess = useAppSelector(state => state.rate.updateSuccess);

  const handleClose = () => {
    navigate('/rate' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProducts({}));
    dispatch(getProfiles({}));
    dispatch(getDurations({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    values.startDate = convertDateTimeToServer(values.startDate);
    values.endDate = convertDateTimeToServer(values.endDate);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...rateEntity,
      ...values,
      product: products.find(it => it.id.toString() === values.product.toString()),
      profile: profiles.find(it => it.id.toString() === values.profile.toString()),
      duration: durations.find(it => it.id.toString() === values.duration.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          updatedAt: displayDefaultDateTime(),
          startDate: displayDefaultDateTime(),
          endDate: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...rateEntity,
          updatedAt: convertDateTimeFromServer(rateEntity.updatedAt),
          startDate: convertDateTimeFromServer(rateEntity.startDate),
          endDate: convertDateTimeFromServer(rateEntity.endDate),
          deletedAt: convertDateTimeFromServer(rateEntity.deletedAt),
          product: rateEntity?.product?.id,
          profile: rateEntity?.profile?.id,
          duration: rateEntity?.duration?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="matrixServiceApp.rate.home.createOrEditLabel" data-cy="RateCreateUpdateHeading">
            <Translate contentKey="matrixServiceApp.rate.home.createOrEditLabel">Create or edit a Rate</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="rate-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('matrixServiceApp.rate.rate')} id="rate-rate" name="rate" data-cy="rate" type="text" />
              <ValidatedField
                label={translate('matrixServiceApp.rate.updatedAt')}
                id="rate-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.rate.startDate')}
                id="rate-startDate"
                name="startDate"
                data-cy="startDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.rate.endDate')}
                id="rate-endDate"
                name="endDate"
                data-cy="endDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.rate.deletedAt')}
                id="rate-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="rate-product"
                name="product"
                data-cy="product"
                label={translate('matrixServiceApp.rate.product')}
                type="select"
              >
                <option value="" key="0" />
                {products
                  ? products.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="rate-profile"
                name="profile"
                data-cy="profile"
                label={translate('matrixServiceApp.rate.profile')}
                type="select"
              >
                <option value="" key="0" />
                {profiles
                  ? profiles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.title}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="rate-duration"
                name="duration"
                data-cy="duration"
                label={translate('matrixServiceApp.rate.duration')}
                type="select"
              >
                <option value="" key="0" />
                {durations
                  ? durations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/rate" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RateUpdate;
