import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CeilingRemovalCoefficient from './ceiling-removal-coefficient';
import CeilingRemovalCoefficientDetail from './ceiling-removal-coefficient-detail';
import CeilingRemovalCoefficientUpdate from './ceiling-removal-coefficient-update';
import CeilingRemovalCoefficientDeleteDialog from './ceiling-removal-coefficient-delete-dialog';

const CeilingRemovalCoefficientRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CeilingRemovalCoefficient />} />
    <Route path="new" element={<CeilingRemovalCoefficientUpdate />} />
    <Route path=":id">
      <Route index element={<CeilingRemovalCoefficientDetail />} />
      <Route path="edit" element={<CeilingRemovalCoefficientUpdate />} />
      <Route path="delete" element={<CeilingRemovalCoefficientDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CeilingRemovalCoefficientRoutes;
