import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Connector from './connector';
import ConnectorDetail from './connector-detail';
import ConnectorUpdate from './connector-update';
import ConnectorDeleteDialog from './connector-delete-dialog';

const ConnectorRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Connector />} />
    <Route path="new" element={<ConnectorUpdate />} />
    <Route path=":id">
      <Route index element={<ConnectorDetail />} />
      <Route path="edit" element={<ConnectorUpdate />} />
      <Route path="delete" element={<ConnectorDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConnectorRoutes;
