import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUnit } from 'app/shared/model/unit.model';
import { getEntities as getUnits } from 'app/entities/unit/unit.reducer';
import { IProduct } from 'app/shared/model/product.model';
import { RateType } from 'app/shared/model/enumerations/rate-type.model';
import { getEntity, updateEntity, createEntity, reset } from './product.reducer';
import { InsuranceType } from 'app/shared/model/enumerations/insurance-type.model';

export const ProductUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const units = useAppSelector(state => state.unit.entities);
  const productEntity = useAppSelector(state => state.product.entity);
  const loading = useAppSelector(state => state.product.loading);
  const updating = useAppSelector(state => state.product.updating);
  const updateSuccess = useAppSelector(state => state.product.updateSuccess);
  const rateTypeValues = Object.keys(RateType);
  const insuranceTypeValues = Object.keys(InsuranceType);

  const handleClose = () => {
    navigate('/product' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUnits({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...productEntity,
      ...values,
      unit: units.find(it => it.id.toString() === values.unit.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          updatedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          rateType: 'FIXED',
          insuranceType: 'PERIODIC_NON_CONVENTIONAL',
          ...productEntity,
          updatedAt: convertDateTimeFromServer(productEntity.updatedAt),
          deletedAt: convertDateTimeFromServer(productEntity.deletedAt),
          unit: productEntity?.unit?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="matrixServiceApp.product.home.createOrEditLabel" data-cy="ProductCreateUpdateHeading">
            <Translate contentKey="matrixServiceApp.product.home.createOrEditLabel">Create or edit a Product</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="product-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('matrixServiceApp.product.slug')} id="product-slug" name="slug" data-cy="slug" type="text" />
              <ValidatedField
                label={translate('matrixServiceApp.product.slugDescription')}
                id="product-slugDescription"
                name="slugDescription"
                data-cy="slugDescription"
                type="text"
              />
              <ValidatedField
                label={translate('matrixServiceApp.product.rateType')}
                id="product-rateType"
                name="rateType"
                data-cy="rateType"
                type="select"
              >
                {rateTypeValues.map(rateType => (
                  <option value={rateType} key={rateType}>
                    {translate('matrixServiceApp.RateType.' + rateType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('matrixServiceApp.product.insuranceType')}
                id="product-insuranceType"
                name="insuranceType"
                data-cy="insuranceType"
                type="select"
              >
                {insuranceTypeValues.map(insuranceType => (
                  <option value={insuranceType} key={insuranceType}>
                    {translate('matrixServiceApp.InsuranceType.' + insuranceType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('matrixServiceApp.product.code')}
                id="product-code"
                name="code"
                data-cy="code"
                type="text"
                placeholder="Product code"
              />
              <ValidatedField
                label={translate('matrixServiceApp.product.updatedAt')}
                id="product-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.product.deletedAt')}
                id="product-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField id="product-unit" name="unit" data-cy="unit" label={translate('matrixServiceApp.product.unit')} type="select">
                <option value="" key="0" />
                {units
                  ? units.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/product" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductUpdate;
