import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MultiRiskInsuranceRateGrid from './multi-risk-insurance-rate-grid';
import MultiRiskInsuranceRateGridDetail from './multi-risk-insurance-rate-grid-detail';
import MultiRiskInsuranceRateGridUpdate from './multi-risk-insurance-rate-grid-update';
import MultiRiskInsuranceRateGridDeleteDialog from './multi-risk-insurance-rate-grid-delete-dialog';

const MultiRiskInsuranceRateGridRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MultiRiskInsuranceRateGrid />} />
    <Route path="new" element={<MultiRiskInsuranceRateGridUpdate />} />
    <Route path=":id">
      <Route index element={<MultiRiskInsuranceRateGridDetail />} />
      <Route path="edit" element={<MultiRiskInsuranceRateGridUpdate />} />
      <Route path="delete" element={<MultiRiskInsuranceRateGridDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MultiRiskInsuranceRateGridRoutes;
