import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import WayType from './way-type';
import WayTypeDetail from './way-type-detail';
import WayTypeUpdate from './way-type-update';
import WayTypeDeleteDialog from './way-type-delete-dialog';

const WayTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<WayType />} />
    <Route path="new" element={<WayTypeUpdate />} />
    <Route path=":id">
      <Route index element={<WayTypeDetail />} />
      <Route path="edit" element={<WayTypeUpdate />} />
      <Route path="delete" element={<WayTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default WayTypeRoutes;
