import dayjs from 'dayjs';
import { IDocument } from 'app/shared/model/document.model';

export interface IDocumentType {
  id?: number;
  slug?: string | null;
  updatedAt?: string | null;
  isActive?: boolean | null;
  documents?: IDocument[] | null;
}

export const defaultValue: Readonly<IDocumentType> = {
  isActive: false,
};
