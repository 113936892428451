import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Organism from './organism';
import OrganismDetail from './organism-detail';
import OrganismUpdate from './organism-update';
import OrganismDeleteDialog from './organism-delete-dialog';

const OrganismRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Organism />} />
    <Route path="new" element={<OrganismUpdate />} />
    <Route path=":id">
      <Route index element={<OrganismDetail />} />
      <Route path="edit" element={<OrganismUpdate />} />
      <Route path="delete" element={<OrganismDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrganismRoutes;
