export enum DurationType {
  DAILY = 'DAILY',

  WEEKLY = 'WEEKLY',

  MONTHLY = 'MONTHLY',

  QUARTERLY = 'QUARTERLY',

  YEARLY = 'YEARLY',
}
