import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './profile-additional-attribute.reducer';

export const ProfileAdditionalAttributeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const profileAdditionalAttributeEntity = useAppSelector(state => state.profileAdditionalAttribute.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="profileAdditionalAttributeDetailsHeading">
          <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.detail.title">ProfileAdditionalAttribute</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{profileAdditionalAttributeEntity.id}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{profileAdditionalAttributeEntity.slug}</dd>
          <dt>
            <span id="value">
              <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.value">Value</Translate>
            </span>
          </dt>
          <dd>{profileAdditionalAttributeEntity.value}</dd>
          <dt>
            <span id="regularExpression">
              <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.regularExpression">Regular Expression</Translate>
            </span>
          </dt>
          <dd>{profileAdditionalAttributeEntity.regularExpression}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.type">Type</Translate>
            </span>
          </dt>
          <dd>{profileAdditionalAttributeEntity.type}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {profileAdditionalAttributeEntity.updatedAt ? (
              <TextFormat value={profileAdditionalAttributeEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="matrixServiceApp.profileAdditionalAttribute.profile">Profile</Translate>
          </dt>
          <dd>{profileAdditionalAttributeEntity.profile ? profileAdditionalAttributeEntity.profile.title : ''}</dd>
        </dl>
        <Button tag={Link} to="/profile-additional-attribute" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/profile-additional-attribute/${profileAdditionalAttributeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProfileAdditionalAttributeDetail;
