import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LegalTemplate from './legal-template';
import LegalTemplateDetail from './legal-template-detail';
import LegalTemplateUpdate from './legal-template-update';
import LegalTemplateDeleteDialog from './legal-template-delete-dialog';

const LegalTemplateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LegalTemplate />} />
    <Route path="new" element={<LegalTemplateUpdate />} />
    <Route path=":id">
      <Route index element={<LegalTemplateDetail />} />
      <Route path="edit" element={<LegalTemplateUpdate />} />
      <Route path="delete" element={<LegalTemplateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LegalTemplateRoutes;
