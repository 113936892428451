import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPlatformUser } from 'app/shared/model/platform-user.model';
import { getEntities as getPlatformUsers } from 'app/entities/platform-user/platform-user.reducer';
import { IContract } from 'app/shared/model/contract.model';
import { getEntities as getContracts } from 'app/entities/contract/contract.reducer';
import { IDocumentPipe } from 'app/shared/model/document-pipe.model';
import { getEntities as getDocumentPipes } from 'app/entities/document-pipe/document-pipe.reducer';
import { ILegalTemplate } from 'app/shared/model/legal-template.model';
import { getEntities as getLegalTemplates } from 'app/entities/legal-template/legal-template.reducer';
import { ILegalStatus } from 'app/shared/model/legal-status.model';
import { getEntities as getLegalStatuses } from 'app/entities/legal-status/legal-status.reducer';
import { IUnit } from 'app/shared/model/unit.model';
import { getEntities as getUnits } from 'app/entities/unit/unit.reducer';
import { ILegal } from 'app/shared/model/legal.model';
import { getEntity, updateEntity, createEntity, reset } from './legal.reducer';

export const LegalUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const platformUsers = useAppSelector(state => state.platformUser.entities);
  const contracts = useAppSelector(state => state.contract.entities);
  const documentPipes = useAppSelector(state => state.documentPipe.entities);
  const legalTemplates = useAppSelector(state => state.legalTemplate.entities);
  const legalStatuses = useAppSelector(state => state.legalStatus.entities);
  const units = useAppSelector(state => state.unit.entities);
  const legalEntity = useAppSelector(state => state.legal.entity);
  const loading = useAppSelector(state => state.legal.loading);
  const updating = useAppSelector(state => state.legal.updating);
  const updateSuccess = useAppSelector(state => state.legal.updateSuccess);

  const handleClose = () => {
    navigate('/legal' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPlatformUsers({}));
    dispatch(getContracts({}));
    dispatch(getDocumentPipes({}));
    dispatch(getLegalTemplates({}));
    dispatch(getLegalStatuses({}));
    dispatch(getUnits({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.deletedAt = convertDateTimeToServer(values.deletedAt);
    values.startedAt = convertDateTimeToServer(values.startedAt);
    values.endedAt = convertDateTimeToServer(values.endedAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...legalEntity,
      ...values,
      users: mapIdList(values.users),
      contract: contracts.find(it => it.id.toString() === values.contract.toString()),
      documentPipe: documentPipes.find(it => it.id.toString() === values.documentPipe.toString()),
      legalTemplate: legalTemplates.find(it => it.id.toString() === values.legalTemplate.toString()),
      legalStatus: legalStatuses.find(it => it.id.toString() === values.legalStatus.toString()),
      unit: units.find(it => it.id.toString() === values.unit.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          deletedAt: displayDefaultDateTime(),
          startedAt: displayDefaultDateTime(),
          endedAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          ...legalEntity,
          deletedAt: convertDateTimeFromServer(legalEntity.deletedAt),
          startedAt: convertDateTimeFromServer(legalEntity.startedAt),
          endedAt: convertDateTimeFromServer(legalEntity.endedAt),
          updatedAt: convertDateTimeFromServer(legalEntity.updatedAt),
          users: legalEntity?.users?.map(e => e.id.toString()),
          contract: legalEntity?.contract?.id,
          documentPipe: legalEntity?.documentPipe?.id,
          legalTemplate: legalEntity?.legalTemplate?.id,
          legalStatus: legalEntity?.legalStatus?.id,
          unit: legalEntity?.unit?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="matrixServiceApp.legal.home.createOrEditLabel" data-cy="LegalCreateUpdateHeading">
            <Translate contentKey="matrixServiceApp.legal.home.createOrEditLabel">Create or edit a Legal</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="legal-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('matrixServiceApp.legal.slug')} id="legal-slug" name="slug" data-cy="slug" type="text" />
              <ValidatedField
                label={translate('matrixServiceApp.legal.slugDescription')}
                id="legal-slugDescription"
                name="slugDescription"
                data-cy="slugDescription"
                type="text"
              />
              <ValidatedField
                label={translate('matrixServiceApp.legal.deletedAt')}
                id="legal-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.legal.createdBy')}
                id="legal-createdBy"
                name="createdBy"
                data-cy="createdBy"
                type="text"
              />
              <ValidatedField
                label={translate('matrixServiceApp.legal.startedAt')}
                id="legal-startedAt"
                name="startedAt"
                data-cy="startedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.legal.endedAt')}
                id="legal-endedAt"
                name="endedAt"
                data-cy="endedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.legal.updatedAt')}
                id="legal-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.legal.user')}
                id="legal-user"
                data-cy="user"
                type="select"
                multiple
                name="users"
              >
                <option value="" key="0" />
                {platformUsers
                  ? platformUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="legal-contract"
                name="contract"
                data-cy="contract"
                label={translate('matrixServiceApp.legal.contract')}
                type="select"
              >
                <option value="" key="0" />
                {contracts
                  ? contracts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="legal-documentPipe"
                name="documentPipe"
                data-cy="documentPipe"
                label={translate('matrixServiceApp.legal.documentPipe')}
                type="select"
              >
                <option value="" key="0" />
                {documentPipes
                  ? documentPipes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="legal-legalTemplate"
                name="legalTemplate"
                data-cy="legalTemplate"
                label={translate('matrixServiceApp.legal.legalTemplate')}
                type="select"
              >
                <option value="" key="0" />
                {legalTemplates
                  ? legalTemplates.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="legal-legalStatus"
                name="legalStatus"
                data-cy="legalStatus"
                label={translate('matrixServiceApp.legal.legalStatus')}
                type="select"
              >
                <option value="" key="0" />
                {legalStatuses
                  ? legalStatuses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="legal-unit" name="unit" data-cy="unit" label={translate('matrixServiceApp.legal.unit')} type="select">
                <option value="" key="0" />
                {units
                  ? units.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.slug}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/legal" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LegalUpdate;
