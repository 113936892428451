import dayjs from 'dayjs';
import { IPlatformUser } from 'app/shared/model/platform-user.model';
import { IContract } from 'app/shared/model/contract.model';
import { IDocumentPipe } from 'app/shared/model/document-pipe.model';
import { ILegalTemplate } from 'app/shared/model/legal-template.model';
import { ILegalStatus } from 'app/shared/model/legal-status.model';
import { IUnit } from 'app/shared/model/unit.model';

export interface ILegal {
  id?: number;
  slug?: string | null;
  slugDescription?: string | null;
  deletedAt?: string | null;
  createdBy?: string | null;
  startedAt?: string | null;
  endedAt?: string | null;
  updatedAt?: string | null;
  users?: IPlatformUser[] | null;
  contract?: IContract | null;
  documentPipe?: IDocumentPipe | null;
  legalTemplate?: ILegalTemplate | null;
  legalStatus?: ILegalStatus | null;
  unit?: IUnit | null;
}

export const defaultValue: Readonly<ILegal> = {};
