import dayjs from 'dayjs';
import { IRate } from 'app/shared/model/rate.model';
import { DurationType } from 'app/shared/model/enumerations/duration-type.model';

export interface IDuration {
  id?: number;
  slug?: string | null;
  value?: number | null;
  durationMin?: number | null;
  durationMax?: number | null;
  durationType?: keyof typeof DurationType | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  rates?: IRate[] | null;
}

export const defaultValue: Readonly<IDuration> = {};
