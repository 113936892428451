import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProfileAdditionalAttribute from './profile-additional-attribute';
import ProfileAdditionalAttributeDetail from './profile-additional-attribute-detail';
import ProfileAdditionalAttributeUpdate from './profile-additional-attribute-update';
import ProfileAdditionalAttributeDeleteDialog from './profile-additional-attribute-delete-dialog';

const ProfileAdditionalAttributeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProfileAdditionalAttribute />} />
    <Route path="new" element={<ProfileAdditionalAttributeUpdate />} />
    <Route path=":id">
      <Route index element={<ProfileAdditionalAttributeDetail />} />
      <Route path="edit" element={<ProfileAdditionalAttributeUpdate />} />
      <Route path="delete" element={<ProfileAdditionalAttributeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfileAdditionalAttributeRoutes;
