import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/legal-template">
        <Translate contentKey="global.menu.entities.legalTemplate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contact-type">
        <Translate contentKey="global.menu.entities.contactType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/document-type">
        <Translate contentKey="global.menu.entities.documentType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organism">
        <Translate contentKey="global.menu.entities.organism" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/location">
        <Translate contentKey="global.menu.entities.location" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/rate">
        <Translate contentKey="global.menu.entities.rate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/city">
        <Translate contentKey="global.menu.entities.city" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/connector">
        <Translate contentKey="global.menu.entities.connector" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/legal-status">
        <Translate contentKey="global.menu.entities.legalStatus" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/bundle">
        <Translate contentKey="global.menu.entities.bundle" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/document">
        <Translate contentKey="global.menu.entities.document" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/document-pipe">
        <Translate contentKey="global.menu.entities.documentPipe" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-additional-attribute">
        <Translate contentKey="global.menu.entities.profileAdditionalAttribute" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/way-type">
        <Translate contentKey="global.menu.entities.wayType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contact">
        <Translate contentKey="global.menu.entities.contact" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/provided-service">
        <Translate contentKey="global.menu.entities.providedService" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/unit">
        <Translate contentKey="global.menu.entities.unit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product">
        <Translate contentKey="global.menu.entities.product" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/legal">
        <Translate contentKey="global.menu.entities.legal" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/duration">
        <Translate contentKey="global.menu.entities.duration" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile">
        <Translate contentKey="global.menu.entities.profile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/country">
        <Translate contentKey="global.menu.entities.country" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/contract">
        <Translate contentKey="global.menu.entities.contract" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/platform-user">
        <Translate contentKey="global.menu.entities.platformUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/vat">
        <Translate contentKey="global.menu.entities.vat" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/multi-risk-insurance-rate-grid">
        <Translate contentKey="global.menu.entities.multiRiskInsuranceRateGrid" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ceiling-removal-coefficient">
        <Translate contentKey="global.menu.entities.ceilingRemovalCoefficient" />
      </MenuItem>

      <MenuItem icon="asterisk" to="/life-insurance-coefficient">
        <Translate contentKey="global.menu.entities.lifeInsuranceCoefficient" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
