import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './legal.reducer';

export const LegalDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const legalEntity = useAppSelector(state => state.legal.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="legalDetailsHeading">
          <Translate contentKey="matrixServiceApp.legal.detail.title">Legal</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{legalEntity.id}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="matrixServiceApp.legal.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{legalEntity.slug}</dd>
          <dt>
            <span id="slugDescription">
              <Translate contentKey="matrixServiceApp.legal.slugDescription">Slug Description</Translate>
            </span>
          </dt>
          <dd>{legalEntity.slugDescription}</dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="matrixServiceApp.legal.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>{legalEntity.deletedAt ? <TextFormat value={legalEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="matrixServiceApp.legal.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{legalEntity.createdBy}</dd>
          <dt>
            <span id="startedAt">
              <Translate contentKey="matrixServiceApp.legal.startedAt">Started At</Translate>
            </span>
          </dt>
          <dd>{legalEntity.startedAt ? <TextFormat value={legalEntity.startedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="endedAt">
              <Translate contentKey="matrixServiceApp.legal.endedAt">Ended At</Translate>
            </span>
          </dt>
          <dd>{legalEntity.endedAt ? <TextFormat value={legalEntity.endedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="matrixServiceApp.legal.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{legalEntity.updatedAt ? <TextFormat value={legalEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.legal.user">User</Translate>
          </dt>
          <dd>
            {legalEntity.users
              ? legalEntity.users.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {legalEntity.users && i === legalEntity.users.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="matrixServiceApp.legal.contract">Contract</Translate>
          </dt>
          <dd>{legalEntity.contract ? legalEntity.contract.slug : ''}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.legal.documentPipe">Document Pipe</Translate>
          </dt>
          <dd>{legalEntity.documentPipe ? legalEntity.documentPipe.slug : ''}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.legal.legalTemplate">Legal Template</Translate>
          </dt>
          <dd>{legalEntity.legalTemplate ? legalEntity.legalTemplate.slug : ''}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.legal.legalStatus">Legal Status</Translate>
          </dt>
          <dd>{legalEntity.legalStatus ? legalEntity.legalStatus.slug : ''}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.legal.unit">Unit</Translate>
          </dt>
          <dd>{legalEntity.unit ? legalEntity.unit.slug : ''}</dd>
        </dl>
        <Button tag={Link} to="/legal" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/legal/${legalEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LegalDetail;
