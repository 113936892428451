import dayjs from 'dayjs';
import { IContact } from 'app/shared/model/contact.model';

export interface IContactType {
  id?: number;
  slug?: string | null;
  parentSlug?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  contacts?: IContact[] | null;
}

export const defaultValue: Readonly<IContactType> = {};
