import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IDocument } from 'app/shared/model/document.model';
import { getEntities as getDocuments } from 'app/entities/document/document.reducer';
import { IDocumentPipe } from 'app/shared/model/document-pipe.model';
import { getEntity, updateEntity, createEntity, reset } from './document-pipe.reducer';

export const DocumentPipeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const documents = useAppSelector(state => state.document.entities);
  const documentPipeEntity = useAppSelector(state => state.documentPipe.entity);
  const loading = useAppSelector(state => state.documentPipe.loading);
  const updating = useAppSelector(state => state.documentPipe.updating);
  const updateSuccess = useAppSelector(state => state.documentPipe.updateSuccess);

  const handleClose = () => {
    navigate('/document-pipe' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getDocuments({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    values.deletedAt = convertDateTimeToServer(values.deletedAt);

    const entity = {
      ...documentPipeEntity,
      ...values,
      documents: mapIdList(values.documents),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          updatedAt: displayDefaultDateTime(),
          deletedAt: displayDefaultDateTime(),
        }
      : {
          ...documentPipeEntity,
          updatedAt: convertDateTimeFromServer(documentPipeEntity.updatedAt),
          deletedAt: convertDateTimeFromServer(documentPipeEntity.deletedAt),
          documents: documentPipeEntity?.documents?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="matrixServiceApp.documentPipe.home.createOrEditLabel" data-cy="DocumentPipeCreateUpdateHeading">
            <Translate contentKey="matrixServiceApp.documentPipe.home.createOrEditLabel">Create or edit a DocumentPipe</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="document-pipe-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('matrixServiceApp.documentPipe.slug')}
                id="document-pipe-slug"
                name="slug"
                data-cy="slug"
                type="text"
              />
              <ValidatedField
                label={translate('matrixServiceApp.documentPipe.path')}
                id="document-pipe-path"
                name="path"
                data-cy="path"
                type="text"
              />
              <ValidatedField
                label={translate('matrixServiceApp.documentPipe.updatedAt')}
                id="document-pipe-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.documentPipe.deletedAt')}
                id="document-pipe-deletedAt"
                name="deletedAt"
                data-cy="deletedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('matrixServiceApp.documentPipe.isActive')}
                id="document-pipe-isActive"
                name="isActive"
                data-cy="isActive"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('matrixServiceApp.documentPipe.document')}
                id="document-pipe-document"
                data-cy="document"
                type="select"
                multiple
                name="documents"
              >
                <option value="" key="0" />
                {documents
                  ? documents.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/document-pipe" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DocumentPipeUpdate;
