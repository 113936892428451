import dayjs from 'dayjs';
import { IConnector } from 'app/shared/model/connector.model';
import { IBundle } from 'app/shared/model/bundle.model';
import { IContract } from 'app/shared/model/contract.model';

export interface IProvidedService {
  id?: number;
  slug?: string | null;
  slugDescription?: string | null;
  updatedAt?: string | null;
  connectors?: IConnector[] | null;
  bundles?: IBundle[] | null;
  contracts?: IContract[] | null;
}

export const defaultValue: Readonly<IProvidedService> = {};
