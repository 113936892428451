import dayjs from 'dayjs';
import { IProfile } from 'app/shared/model/profile.model';

export interface IProfileAdditionalAttribute {
  id?: number;
  slug?: string | null;
  value?: string | null;
  regularExpression?: string | null;
  type?: string | null;
  updatedAt?: string | null;
  profile?: IProfile | null;
}

export const defaultValue: Readonly<IProfileAdditionalAttribute> = {};
