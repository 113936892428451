import dayjs from 'dayjs';
import { IProduct } from 'app/shared/model/product.model';
import { IProfile } from 'app/shared/model/profile.model';
import { IDuration } from 'app/shared/model/duration.model';

export interface IRate {
  id?: number;
  rate?: number | null;
  updatedAt?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  deletedAt?: string | null;
  product?: IProduct | null;
  profile?: IProfile | null;
  duration?: IDuration | null;
}

export const defaultValue: Readonly<IRate> = {};
