import dayjs from 'dayjs';
import { IContact } from 'app/shared/model/contact.model';

export interface ICountry {
  id?: number;
  slug?: string | null;
  phonePrefix?: string | null;
  geoJsonPolygone?: string | null;
  capitalSlug?: string | null;
  descriptionSlug?: string | null;
  phoneRegex?: string | null;
  updatedAt?: string | null;
  contacts?: IContact[] | null;
}

export const defaultValue: Readonly<ICountry> = {};
