import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './document-pipe.reducer';

export const DocumentPipeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const documentPipeEntity = useAppSelector(state => state.documentPipe.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="documentPipeDetailsHeading">
          <Translate contentKey="matrixServiceApp.documentPipe.detail.title">DocumentPipe</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{documentPipeEntity.id}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="matrixServiceApp.documentPipe.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{documentPipeEntity.slug}</dd>
          <dt>
            <span id="path">
              <Translate contentKey="matrixServiceApp.documentPipe.path">Path</Translate>
            </span>
          </dt>
          <dd>{documentPipeEntity.path}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="matrixServiceApp.documentPipe.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {documentPipeEntity.updatedAt ? <TextFormat value={documentPipeEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="deletedAt">
              <Translate contentKey="matrixServiceApp.documentPipe.deletedAt">Deleted At</Translate>
            </span>
          </dt>
          <dd>
            {documentPipeEntity.deletedAt ? <TextFormat value={documentPipeEntity.deletedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="matrixServiceApp.documentPipe.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{documentPipeEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.documentPipe.document">Document</Translate>
          </dt>
          <dd>
            {documentPipeEntity.documents
              ? documentPipeEntity.documents.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {documentPipeEntity.documents && i === documentPipeEntity.documents.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/document-pipe" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/document-pipe/${documentPipeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DocumentPipeDetail;
