import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './legal-template.reducer';

export const LegalTemplateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const legalTemplateEntity = useAppSelector(state => state.legalTemplate.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="legalTemplateDetailsHeading">
          <Translate contentKey="matrixServiceApp.legalTemplate.detail.title">LegalTemplate</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{legalTemplateEntity.id}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="matrixServiceApp.legalTemplate.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{legalTemplateEntity.slug}</dd>
          <dt>
            <span id="dynamicHTtmlContent">
              <Translate contentKey="matrixServiceApp.legalTemplate.dynamicHTtmlContent">Dynamic H Ttml Content</Translate>
            </span>
          </dt>
          <dd>{legalTemplateEntity.dynamicHTtmlContent}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="matrixServiceApp.legalTemplate.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {legalTemplateEntity.updatedAt ? (
              <TextFormat value={legalTemplateEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/legal-template" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/legal-template/${legalTemplateEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LegalTemplateDetail;
