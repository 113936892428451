import dayjs from 'dayjs';
import { ILocation } from 'app/shared/model/location.model';

export interface IWayType {
  id?: number;
  slug?: string | null;
  updatedAt?: string | null;
  locations?: ILocation[] | null;
}

export const defaultValue: Readonly<IWayType> = {};
