import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProvidedService from './provided-service';
import ProvidedServiceDetail from './provided-service-detail';
import ProvidedServiceUpdate from './provided-service-update';
import ProvidedServiceDeleteDialog from './provided-service-delete-dialog';

const ProvidedServiceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProvidedService />} />
    <Route path="new" element={<ProvidedServiceUpdate />} />
    <Route path=":id">
      <Route index element={<ProvidedServiceDetail />} />
      <Route path="edit" element={<ProvidedServiceUpdate />} />
      <Route path="delete" element={<ProvidedServiceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProvidedServiceRoutes;
