import dayjs from 'dayjs';
import { IPlatformUser } from 'app/shared/model/platform-user.model';
import { IUnit } from 'app/shared/model/unit.model';
import { ILocation } from 'app/shared/model/location.model';

export interface IOrganism {
  id?: number;
  slug?: string | null;
  slugDescription?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  platformUsers?: IPlatformUser[] | null;
  units?: IUnit[] | null;
  location?: ILocation | null;
}

export const defaultValue: Readonly<IOrganism> = {};
