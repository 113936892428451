import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './contract.reducer';

export const ContractDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contractEntity = useAppSelector(state => state.contract.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="contractDetailsHeading">
          <Translate contentKey="matrixServiceApp.contract.detail.title">Contract</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{contractEntity.id}</dd>
          <dt>
            <span id="slug">
              <Translate contentKey="matrixServiceApp.contract.slug">Slug</Translate>
            </span>
          </dt>
          <dd>{contractEntity.slug}</dd>
          <dt>
            <span id="slugDescription">
              <Translate contentKey="matrixServiceApp.contract.slugDescription">Slug Description</Translate>
            </span>
          </dt>
          <dd>{contractEntity.slugDescription}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="matrixServiceApp.contract.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{contractEntity.updatedAt ? <TextFormat value={contractEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="matrixServiceApp.contract.providedService">Provided Service</Translate>
          </dt>
          <dd>
            {contractEntity.providedServices
              ? contractEntity.providedServices.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {contractEntity.providedServices && i === contractEntity.providedServices.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/contract" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/contract/${contractEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContractDetail;
