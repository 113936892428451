import dayjs from 'dayjs';
import { ILocation } from 'app/shared/model/location.model';

export interface ICity {
  id?: number;
  slug?: string | null;
  zipCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  updatedAt?: string | null;
  locations?: ILocation[] | null;
}

export const defaultValue: Readonly<ICity> = {};
