import dayjs from 'dayjs';
import { IPlatformUser } from 'app/shared/model/platform-user.model';
import { IContactType } from 'app/shared/model/contact-type.model';
import { ICountry } from 'app/shared/model/country.model';

export interface IContact {
  id?: number;
  value?: string | null;
  isPrincipal?: boolean | null;
  isKeyAuthenticator?: boolean | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  platformUsers?: IPlatformUser[] | null;
  contactType?: IContactType | null;
  country?: ICountry | null;
}

export const defaultValue: Readonly<IContact> = {
  isPrincipal: false,
  isKeyAuthenticator: false,
};
