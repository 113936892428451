import dayjs from 'dayjs';
import { ILocation } from 'app/shared/model/location.model';
import { IContact } from 'app/shared/model/contact.model';
import { IOrganism } from 'app/shared/model/organism.model';
import { IUnit } from 'app/shared/model/unit.model';
import { ILegal } from 'app/shared/model/legal.model';

export interface IPlatformUser {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  updatedAt?: string | null;
  location?: ILocation | null;
  contact?: IContact | null;
  organism?: IOrganism | null;
  unit?: IUnit | null;
  legals?: ILegal[] | null;
}

export const defaultValue: Readonly<IPlatformUser> = {};
