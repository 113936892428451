import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LifeInsuranceCoefficient from './life-insurance-coefficient';
import LifeInsuranceCoefficientDetail from './life-insurance-coefficient-detail';
import LifeInsuranceCoefficientUpdate from './life-insurance-coefficient-update';
import LifeInsuranceCoefficientDeleteDialog from './life-insurance-coefficient-delete-dialog';

const CeilingRemovalCoefficientRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LifeInsuranceCoefficient />} />
    <Route path="new" element={<LifeInsuranceCoefficientUpdate />} />
    <Route path=":id">
      <Route index element={<LifeInsuranceCoefficientDetail />} />
      <Route path="edit" element={<LifeInsuranceCoefficientUpdate />} />
      <Route path="delete" element={<LifeInsuranceCoefficientDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CeilingRemovalCoefficientRoutes;
