import dayjs from 'dayjs';
import { IPlatformUser } from 'app/shared/model/platform-user.model';
import { IOrganism } from 'app/shared/model/organism.model';
import { IUnit } from 'app/shared/model/unit.model';
import { IWayType } from 'app/shared/model/way-type.model';
import { ICity } from 'app/shared/model/city.model';

export interface ILocation {
  id?: number;
  wayNumber?: number | null;
  wayName?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  updatedAt?: string | null;
  platformUsers?: IPlatformUser[] | null;
  organisms?: IOrganism[] | null;
  units?: IUnit[] | null;
  wayType?: IWayType | null;
  city?: ICity | null;
}

export const defaultValue: Readonly<ILocation> = {};
